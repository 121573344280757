<template>
  <div class="container step-container">
    <div class="content-wrapper">
      <label class="label step-field no-margin">
        Telemedicine
      </label>
      <b-field v-for="option in telemedicineOptions" :key="option"
        class="step-field no-margin wrap-network-field">
        <b-radio
          name="telemedicine"
          size="is-medium"
          :native-value="option"
          v-model="formData.telemedicine"
          @input="unsetTelemedicineCustom()">
          <span :class="[option === 'None or custom' ? 'has-text-grey' : '', 'open-sans']">
            {{ option }}
          </span>
        </b-radio>
      </b-field>
      <b-field
        label="PBM"
        class="step-field custom-select"
        :type="formDisplay.pbmName.type"
        :message="formDisplay.pbmName.message">
        <CustomSelect
          placeholder="Select PBM Name"
          :options="pbmOptions"
          :type="formDisplay.pbmName.type"
          :default="formData.pbmName"
          @input="selectPBMName"/>
      </b-field>
      <b-field
        label="PBM Site URL (if applicable)"
        :label-position="labelPosition"
        class="step-field">
        <b-input
          @blur="checkURLInput('pbmSiteURL')"
          size="is-medium"
          v-model.trim="formData.pbmSiteURL">
        </b-input>
      </b-field>
      <b-field
        label="Formulary URL (if applicable)"
        :label-position="labelPosition"
        class="step-field">
        <b-input
          @blur="checkURLInput('formularyUrl')"
          size="is-medium"
          v-model.trim="formData.formularyUrl">
        </b-input>
      </b-field>
      <br>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import CustomSelect from './custom-select.vue'

export default {
  name: 'coverage-details',
  data () {
    const formDataDefault = this.$store.getters['plans/coverageDetails/getCoverageDetailsData'] ?? {
      telemedicine: undefined,
      telemedicineCustom: undefined,
      pbmName: undefined,
      pbmSiteURL: undefined,
      formularyUrl: undefined,
    }

    return {
      labelPosition: 'inside',
      telemedicineOptions: this.$store.getters['plans/getTelemedicineOptions'],
      pbmOptions: this.$store.getters['plans/getPbmNames'],
      formData: formDataDefault,
      formDisplay: {
        telemedicine: { type: '', message: '' },
        telemedicineCustom: { type: '', message: '' },
        pbmName: { type: '', message: '' },
      },
      formErrors: {
        telemedicineCustom: 'Please enter a telemedicine provider',
        pbmName: 'Please select a PBM'
      }
    }
  },
  methods: {
    setFieldInvalid (field) {
      this.formDisplay[field].type = 'is-danger'
      this.formDisplay[field].message = this.formErrors[field]
    },
    setFieldValid (field) {
      this.formDisplay[field].type = 'is-success'
      this.formDisplay[field].message = ''
    },
    setFieldDefault (field) {
      this.formDisplay[field].type = ''
      this.formDisplay[field].message = ''
    },
    isFieldOptional (field) {
      if (Object.keys(this.formErrors).includes(field) && field !== 'telemedicineCustom') {
        return true
      }
      return false
    },
    checkFieldHasValue (field) {
      if (this.formData[field] === undefined || this.formData[field] === null || this.formData[field] === '') {
        if (this.isFieldOptional(field)) {
          this.setFieldValid(field)
          return
        }
        this.setFieldInvalid(field)
      } else {
        this.setFieldValid(field)
      }
    },
    checkURLInput (url) {
      const currentURL = this.formData[url]

      if (currentURL && !(currentURL.startsWith('https://'))) {
        this.formData[url] = 'https://' + currentURL
      }
    },
    unsetTelemedicineCustom () {
      this.setFieldValid('telemedicine')
      this.formDisplay.telemedicineCustom.type = ''
      this.formDisplay.telemedicineCustom.message = ''
    },
    selectPBMName (value) {
      this.formData.pbmName = value
      if (value === 'Other') {
        this.formData.pbmName = null
      }
      this.setFieldValid('pbmName')
    },
    saveSubmit () {
      let validationFailed = false

      Object.keys(this.formData).filter((key) => {
        return key.includes('telemedicine', 'telemedicineCustom')
      }).forEach(field => {
        if (!this.formData[field] && (field !== 'telemedicineCustom' || this.formData.telemedicine === 'Other')) {
          if (!this.formErrors[field]) {
            return
          }
          this.formDisplay[field].type = 'is-danger'
          this.formDisplay[field].message = this.formErrors[field] || '-'
        } else if (field !== 'telemedicineCustom' || this.formData.telemedicine === 'Other') {
          this.setFieldValid(field)
        } else {
          this.formDisplay[field].type = ''
          this.formDisplay[field].message = ''
        }
      })

      Object.keys(this.formData).filter((key) => {
        return !key.includes('telemedicine', 'telemedicineCustom')
      }).forEach(field => {
        switch (field) {
          case 'pbmSiteURL':
          case 'formularyUrl':
            this.checkURLInput(field)
            break
          default:
            if (this.formData[field] === undefined || this.formData[field] === null || this.formData[field] === '') {
              if (this.isFieldOptional(field)) {
                this.setFieldValid(field)
                return
              }
              this.setFieldInvalid(field)
              validationFailed = true
            } else {
              this.setFieldValid(field)
            }
        }
      })

      if (validationFailed) {
        return false
      } else {
        this.$store.dispatch('plans/coverageDetails/setCoverageDetailsData', this.formData)
        return true
      }
    }
  },
  components: {
    CustomSelect
  },
  directives: {
    mask
  }
}
</script>

<style scoped>
.text-left {
  text-align: left;
}

.wrap-network-field .field-body .field {
  flex-direction: column;
}
.wrap-network-field .field-body .field label {
  margin-bottom: .75rem;
}
.step-container {
  width: 35%;
}
.step-field {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
}

.step-field:not(:first-child):not(.no-margin) {
  margin-bottom: 40px;
  margin-top: 30px;
}
.columns.step-field:not(:first-child):not(.no-margin) {
  margin-bottom: 24px;
  margin-top: 24px;
}

.input-margin-top {
    margin-top: 40px;
    margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
  .step-container {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .step-container {
    width: 80%;
  }
}

.radio-area {
  display: flex;
  flex-direction: column;
}
</style>
