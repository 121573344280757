
import { defineComponent } from 'vue'
import { PropType } from 'vue/types/v3-component-props'

import CentivoCareSection from './centivo-care-section.vue'
import Navbar from './preview-navbar.vue'
import HeroSection from './hero-section.vue'
import ProviderSearch from './provider-search.vue'
import NoeSection from './noe-section.vue'
import TestimonialsSection from './testimonials-section.vue'
import WcygSection from './wcyg-section.vue'
import { TemplateId, templateFields } from '../customization-form/data'

export default defineComponent({
  name: 'oe-preview',
  components: {
    Navbar,
    HeroSection,
    ProviderSearch,
    CentivoCareSection,
    NoeSection,
    TestimonialsSection,
    WcygSection
  },
  props: {
    isMobile: {
      type: Boolean,
      required: false
    },
    headline: {
      type: String,
      required: false
    },
    hero: {
      type: Object as PropType<{ data: string; name: string }>,
      required: false
    },
    logo: {
      type: Object as PropType<{ data: string; name: string }>,
      required: false
    },
    alreadyMember: {
      type: Boolean,
      required: false,
      default: true
    },
    disclaimer: {
      type: Boolean,
      required: false,
      default: true
    },
    networkOfExcellence: {
      type: Array as PropType<string[]>,
      required: false,
      default () {
        return []
      }
    },
    withCentivo: {
      type: Array as PropType<string[]>,
      required: false,
      default () {
        return []
      }
    },
    selectedTemplate: {
      type: String as PropType<TemplateId>,
      required: false
    },
    testimonials: {
      type: Boolean,
      required: false,
      default: true
    },
    vpc: {
      type: Boolean,
      required: false
    },
    centivoCare: {
      type: Object as PropType<{ title: string, body: string, link: string, text: string, enabled: Boolean }>,
      required: false
    },
    phoneEnabled: {
      type: Boolean,
      required: false
    },
    phone: {
      type: String,
      required: false
    },
    clientConfig: {
      type: Object,
      required: true
    },
    heroButtonEnabled: {
      type: Boolean,
      required: false
    },
    heroButtonText: {
      type: String,
      required: false
    },
    heroButtonUrl: {
      type: String,
      required: false
    },
    videoEnabled: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    enabledFields (): Record<string, unknown> {
      return templateFields[this.selectedTemplate || 'template-1']
    },

    vpcDisabled (): boolean {
      if (!this.clientConfig?.networkConfigs) return true
      return !this.clientConfig.hasPcpNetwork
    }
  }
})
