import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Beufy, { ConfigProgrammatic } from 'buefy'
import { Plugin } from 'vue-fragment'
import PortalVue from 'portal-vue'
import store from './store/store'
import './styles/global.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faSearch, faMapMarkerAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faUserSecret as IconDefinition,
  faSearch as IconDefinition,
  faMapMarkerAlt as IconDefinition,
  faTimesCircle as IconDefinition
)
Vue.component('vue-fontawesome', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(Beufy)
Vue.use(PortalVue)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.use(Plugin)

ConfigProgrammatic.setOptions({
  // @ts-ignore
  defaultStatusIcon: false
})
