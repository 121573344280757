
import { PropType } from 'vue/types/v3-component-props'
import { defineComponent } from 'vue'

import dashedBg from '../../assets/oe-preview/dashed-line.svg'
import { TemplateId, templateFields } from '../customization-form/data'
import SectionContainer from './section.vue'

const defaultHero = require('../../assets/custom-default/default-hero.svg')
const defaultVideoThumb = require('../../assets/oe-preview/hero-image-video.svg')
const playIcon = require('../../assets/oe-preview/play-icon.svg')

export default defineComponent({
  name: 'preview-hero',
  data () {
    return {
      defaultHero,
      defaultVideoThumb,
      playIcon
    }
  },
  props: {
    headline: {
      type: String,
      required: false
    },
    hero: {
      type: Object as PropType<{ data: string; name: string }>,
      required: false
    },
    selectedTemplate: {
      type: String as PropType<TemplateId>,
      required: false,
      default: 'template-1'
    },
    heroButtonEnabled: {
      type: Boolean,
      required: false
    },
    heroButtonText: {
      type: String,
      required: false
    },
    heroButtonUrl: {
      type: String,
      required: false
    },
    videoEnabled: {
      type: Boolean,
      required: false
    }
  },
  components: {
    SectionContainer
  },
  computed: {
    cssVars (): { '--bg-img': string } {
      return {
        '--bg-img': `url(${dashedBg})`
      }
    },
    headlineText (): string {
      const defaultHeadline = templateFields[this.selectedTemplate].headline

      return (!this.headline || this.headline === '') ? defaultHeadline.placeholder : this.headline
    }
  }
})
